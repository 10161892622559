<template >
  <div class="opening-hours-table-container">
    <table class="table table-bordered table-striped text-center">
      <tbody>
        <tr v-for="(timing, index) in hours" :key="'hours_' + index">
          <td>
            {{ timing.day }}
          </td>
          <td>
            <template v-if="timing.first_is_closed == 0">
              {{ timing.first_no_time == 0 ? timing.first_time_section : "" }}
            </template>
            <template v-else>
              {{ $t("Gesloten") }}
            </template>
          </td>
          <td>
            <template v-if="timing.second_is_closed == 0">
              {{ timing.second_no_time == 0 ? timing.second_time_section : "" }}
            </template>
            <template v-else>
              {{ $t("Gesloten") }}
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    hours: {
      type: Array,
      default: () => [],
    },
  },
};
</script>